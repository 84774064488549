<!-- @format -->

<template>
	<div class="statistics-table flex col col-start">
		<h3 class="title">
			{{ values.title }}
		</h3>
		<div class="table_body">
			<div class="table_th flex between">
                <span>
                    排名
                </span>
				<span v-for="(item, index) in values.name" :key="index">{{
					item
				}}</span>
			</div>
			<div class="table_main_body">
				<div class="table_inner_body" :style="{ top: top + 'px' }" ref="tbody">
					<div class="table_tr" v-for="(item, index) in data" :key="index">
						<div class="box flex between">
                            <span>{{ index + 1 }}</span>
							<span v-for="(it, i) in item" :key="i">{{ it }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "StatisticsTable",
		props: {
			title: {
				type: String,
				default: "statistics table",
			},
			values: {
				type: Object,
			},
		},
		computed: {
			data() {
				// 当前数据 复制一份到数据当中
				if (this.values.value.length < 5) return this.values.value;
				return [...this.values.value, ...this.values.value];
			},
		},
		data() {
			return {
				// 当前轮播
				index: 0,
				// 当前滚动的高度
				top: 0,
				// 初始化之后 table_body 的高度
				maxTop: 0,
			};
		},
		mounted() {
			setTimeout(() => {
				if (this.values.value.length < 5) return;
				this.init();
			}, 1500);
		},
		methods: {
			// 轮播图滚动之后的回调
			changeInfo(e) {
				this.index = e;
			},
			// 开始执行轮播
			init() {
				// 初始化之后 table_body 的高度
				this.maxTop = this.$refs.tbody.getBoundingClientRect().height / 2;
				// 滚动到了初始化 table_body 的高度 马上回到最开始的地方
				setInterval(() => {
					if (Math.abs(this.top) < this.maxTop) {
						this.top -= 1;
					} else {
						this.top = 0;
					}
				}, 50);
			},
		},
	};
</script>

<style scoped lang="scss">
	@import "css/index.scss";
</style>
