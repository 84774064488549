import Axios from "axios";

const axios = Axios.create({
    baseURL: "https://zlk.snkjol.com/",
    // timeout: 1000,
});

// 响应拦截器
axios.interceptors.response.use(response => {
    // console.log(response)
    return new Promise((resolve, reject) => {
        if (response.data.code == 0) {
            return resolve(response.data.data)
        } else {
            return resolve(response.data.msg)
        }
    })
})

export default axios;