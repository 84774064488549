<!-- @format -->

<template>
	<div id="app">
		<div class="statistics-container">
			<h1 class="relative">
				<div class="absolute">醉马精酿啤酒可视化数据大屏</div>
				醉马精酿啤酒可视化数据大屏
			</h1>

			<div class="main flex between col-start">
				<div class="left">
					<div class="statistics flex between">
						<div class="quantity flex col">
							<p class="name">累计销售额</p>
							<div class="value">
								<p>
									<span>{{ TQuantity }}</span
									><i>元</i>
								</p>
							</div>
						</div>
						<div class="accumulated flex col">
							<p class="name">累计订单</p>
							<div class="value">
								<p>
									<span>{{ TQuantityCount }}</span
									><i>单</i>
								</p>
							</div>
						</div>
					</div>

					<div class="table-swiper">
						<StatisticsTable :values="release" :title="release.title" />

						<StatisticsTable :values="transaction" :title="transaction.title" />
					</div>
				</div>

				<!-- 中间区域 -->
				<div class="statistics-center">
					<!-- 专利数量 -->
					<div class="patent-count flex col col-start between">
						<div class="included flex">
							<span class="name"> 投放设备总数量 </span>
							<ul class="flex between">
								<li v-for="(item, index) in thisyear" :key="index">
									{{ item }}
								</li>
								<li>台</li>
							</ul>
						</div>
						<div class="count flex">
							<span class="name"> 代理商总数 </span>
							<ul class="flex between">
								<li v-for="(item, index) in accumulative" :key="index">
									{{ item }}
								</li>
								<li>家</li>
							</ul>
						</div>
						<div class="count flex">
							<span class="name"> 商家总数 </span>
							<ul class="flex between">
								<li v-for="(item, index) in business" :key="index">
									{{ item }}
								</li>
								<li>家</li>
							</ul>
						</div>
					</div>

					<!-- 专利交易信息 1 -->
					<div class="patent-info flex col col-start">
						<h3>设备分布占比及排行</h3>
						<!-- echarts 区域 -->
						<div class="content flex between">
							<div class="pie" ref="pie"></div>
							<!-- 饼状图 -->
							<ul class="pie-info flex col">
								<li
									class="flex"
									v-for="(item, index) in pieBaseData"
									:key="index"
								>
									<span class="name"> NO.{{ index + 1 }} </span>
									<span class="value">
										{{ item.name }}
									</span>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<!-- 右边区域 -->
				<div class="right">
					<div class="statistics flex between">
						<div class="quantity flex col">
							<p class="name">今日销售额</p>
							<div class="value">
								<p>
									<span>{{ yearPrice }}</span
									><i>元</i>
								</p>
							</div>
						</div>
						<div class="accumulated flex col">
							<p class="name">今日订单</p>
							<div class="value">
								<p>
									<span>{{ countPrice }}</span
									><i>单</i>
								</p>
							</div>
						</div>
					</div>

					<StatisticsTable :values="type" :title="type.title" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import StatisticsTable from "./components/StatisticsTable/index.vue";
	import * as echarts from "echarts";
	import axios from "./axios/index";

	export default {
		components: {
			StatisticsTable,
		},
		data() {
			return {
				// 热销商品
				release: {
					title: "热销商品",
					name: ["商品名称", "销量"],
					value: [],
				},
				// 热门设据
				transaction: {
					title: "热门设备",
					name: ["设备名称", "销量"],
					value: [],
				},
				// 各地区交易情况
				type: {
					title: "各地区交易情况",
					name: ["城市", "销售额"],
					value: [],
				},
				// 收录量
				thisyear: "000000",
				// 总收录量
				accumulative: "000000",
				// business
				business: "000000",
				// 当年交易金额
				yearPrice: 0,
				// 累计交易金额
				countPrice: 100,
				// 交易数量
				TQuantity: 0,
				// 累计交易数量
				TQuantityCount: 100,
				// 饼图实例对象
				pieChart: null,
				// 饼图数据
				pieBaseData: [
					{
						value: 335,
						name: "新能源",
					},
					{
						value: 679,
						name: "能源化工",
					},
					{
						value: 1548,
						name: "新材料",
					},
					{
						value: 1548,
						name: "智能制造",
					},
					{
						value: 1565,
						name: "食品预制菜",
					},
				],
				// 饼图颜色
				pieColor: ["#00B457", "#0085FF", "#B300FF", "#0400FF", "#00AAFF"],
				// 计时器,方便离开的时候清空
				timer: null,
			};
		},
		mounted() {
			// 获取到 echarts 容器
			this.pieChart = echarts.init(this.$refs.pie);

			// 页面发生变化
			window.addEventListener("resize", () => {
				this.pieChart.dispose();
				this.pieChart = echarts.init(this.$refs.pie);
				// 开始执行绘画
				this.initPie();
			});

			// 热门商品
			this.getReleaseData();
			// 热门设备
			this.getTransactionData();
			this.getTypeData();
			// 专利交易信息地区与分类
			this.getPieAndGauge();
			// 专利交易数量当年以及累计
			this.getAccumulative();
			// 专利交易金额当年以及累计
			this.getCountPrice();
			// 收录发布专利数以及累计收录专利数
			this.counteReleaseNumber();
			// 初始化所有的 echatrs

			this.initData();
		},
		methods: {
			// 初始化饼状图
			initPie() {
				let data = this.pieBaseData.map((item, index) => {
					return {
						...item,
						label: {
							color: "#fff",
						},
						itemStyle: {
							color: this.pieColor[index],
						},
					};
				});
				let option = {
					tooltip: {
						trigger: "item",
						formatter: "{b}: {c} ({d}%)",
					},
					legend: {
						show: false,
					},
					series: [
						{
							type: "pie",
							selectedMode: "single",
							selectedOffset: 0,
							radius: [0, "100%"],
							left: 0,
							// 颜色盘
							colorBy: "data",

							label: {
								normal: {
									position: "inner",
								},
							},
							labelLine: {
								normal: {
									show: false,
								},
							},
							emphasis: {
								scale: false,
							},
							data,
						},
					],
				};
				this.pieChart.setOption(option);
			},

			// 热门商品
			async getReleaseData() {
				const res = await axios.get("/api.Largescreen/getLargescreenzlfb");

				this.release.value = res.map((item) => {
					return {
						name: item.patent_name,
						value: item.create_time?.split(" ")[0],
					};
				});

				this.release.value = [
					{
						name: "不锈钢精酿啤酒桶30L蓝莓",
						value: 300,
					},
					{
						name: "不锈钢精酿啤酒桶30L百香果",
						value: 276,
					},
					{
						name: "不锈钢精酿啤酒桶30L黑啤",
						value: 486,
					},
					{
						name: "不锈钢精酿啤酒桶30L白啤",
						value: 158,
					},
					{
						name: "不锈钢精酿啤酒桶30L黄啤",
						value: 200,
					},
				];
			},

			// 热门设备
			async getTransactionData() {
				// const res = await axios.get("/api.Largescreen/getLargescreenzljy");
				// this.transaction.value = res.map((item) => {
				// 	return {
				// 		name: item.patent_name,
				// 		value: item.price,
				// 	};
				// });
				this.transaction.value = [
					{
						name: "封门式打酒机",
						value: 492,
					},
				];
			},

			// 专利交易信息地区与分类
			async getPieAndGauge() {
				const res = await axios.get("/api.Largescreen/getLargescreenzljyxxfl");
				// console.log(res)
				res.type = res.type.filter((item) => item.value);
				res.area = res.area.filter((item) => item.value);
				// this.pieBaseData = res.type;
        this.pieBaseData = [
					{
						name: "成都",
						value: "364",
					},
					{
						name: "乐山",
						value: "326",
					},
					{
						name: "南充",
						value: "196",
					},
					{
						name: "眉山",
						value: "170",
					},
					{
						name: "资阳",
						value: "168",
					},
					{
						name: "遂宁",
						value: "126",
					},
					{
						name: "雅安",
						value: "70",
					},
				]

				// 初始化 echatrs
				this.initPie();
			},
			// 收录发布专利数以及累计收录专利数
			async getAccumulative() {
				// const res = await axios.get("/api.Largescreen/getLargescreenslzlljsl");
				// console.log(res);
				// this.thisyear = res.record.toString().padStart(6, 0);
				// this.accumulative = res.accumulative.toString().padStart(6, 0);
				this.thisyear = "000492";
				this.accumulative = "000104";
				this.business = "000378";
			},

			// 专利交易金额当年以及累计
			async getCountPrice() {
				// const res = await axios.get("/api.Largescreen/getLargescreenzljyjnlj");
				// console.log(res)
				// this.yearPrice = res.jnje;
				this.yearPrice = 2054;
				// this.countPrice = res.ljje;
				this.countPrice = 13;
			},
			// 专利交易数量当年以及累计
			async counteReleaseNumber() {
				const res = await axios.get("/api.Largescreen/getLargescreenzljysldn");
				// console.log(res)
				// this.TQuantity = res.Thisyear || 0;
				this.TQuantity = 224360;
				// this.TQuantityCount = res.accumulative;
				this.TQuantityCount = 1420;
			},
			// 专利类型数据
			async getTypeData() {
				// const res = await axios.get("/api.Largescreen/getLargescreenzllxsj");
				// this.type.value = res;
				this.type.value = [
					{
						name: "成都",
						value: "364",
					},
					{
						name: "乐山",
						value: "326",
					},
					{
						name: "南充",
						value: "196",
					},
					{
						name: "眉山",
						value: "170",
					},
					{
						name: "资阳",
						value: "168",
					},
					{
						name: "遂宁",
						value: "126",
					},
					{
						name: "雅安",
						value: "70",
					},
				];
			},
			// 初始化数据，并开启轮询
			initData() {
				let that = this;
				clearTimeout(this.timer);
				this.timer = null;
				this.timer = setTimeout(function () {
					// 专利交易信息地区与分类
					that.getPieAndGauge();
					// 递归调用函数
					that.initData();
				}, 5000);
			},
		},

		// 组件卸载之前
		beforeUnmount() {
			clearTimeout(this.timer);
			this.timer = null;
			// 页面发生变化
			window.removeEventListener("resize");
		},
	};
</script>

<style scoped lang="scss">
	@import "./assets/index.scss";
</style>
